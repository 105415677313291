import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import Menu from "../components/PizzaMenus/Menu";
import MenuList from "../components/PizzaMenus/MenuList";
import { data } from "../data";

const SeafoodAndSubs = () => {
  return (
    <Layout title="Tasty Pizza – Seafood And Subs">
      <PageBanner
        title={data.seafoodAndSubs.pageHeading}
        image={data.seafoodAndSubs.image}
      />
      <div className="container">
        <div className="row margin-top">
          <div className="col-lg-8 ">
            <Menu menu={data.seafoodAndSubs.subMenu} />
          </div>
          <div className="col-lg-4 ">
            <MenuList menu={data.seafoodAndSubs.fishAndChips} />
            <MenuList menu={data.seafoodAndSubs.familyPack} />
            <MenuList menu={data.seafoodAndSubs.other} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <StaticImage
              src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623708606/Tasty%20Pizza/shrimps2_p5bhqo.jpg"
              alt="Tasty Pizza"
              className="food-img"
            />
          </div>
          <div className="col-lg-4">
            <StaticImage
              src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623708582/Tasty%20Pizza/5_xehfe0.jpg"
              alt="Tasty Pizza"
              className="food-img"
            />
          </div>
          <div className="col-lg-4">
            <StaticImage
              src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623708595/Tasty%20Pizza/fish_and_chips_roodmx.jpg"
              alt="Tasty Pizza"
              className="food-img"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SeafoodAndSubs;
